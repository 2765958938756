<script setup lang="ts">
const { title } = defineProps<{ title?: string }>()

const footerContext = inject<{ addFooterItem: (title: string) => void, toggle: (payload: string) => void }>('footerContextKey', {
  addFooterItem: () => {},
  toggle: () => {},
})

title && footerContext.addFooterItem(title)

function onFooterItemClick(title?: string) {
  title && footerContext.toggle(title)
}
</script>

<template>
  <div>
    <h3 class="hidden text-base fw600 md:block n-text-heading">
      <template v-if="title">
        {{ title }}
      </template>
      <NSkeleton v-else class="w-2/3" pulse />
    </h3>

    <button class="w-full border-b border-slate-300 py-4 text-base fw600 md:hidden n-text-heading" @click="onFooterItemClick(title)">
      <span class="w-full inline-flex items-center justify-between">
        <template v-if="title">
          {{ title }}
        </template>
        <NSkeleton v-else class="w-1/2" pulse />

        <NIcon icon="i-heroicons:chevron-down" class="ml2 h5 w5 -mr1" />

      </span>
    </button>

    <ul role="list" class="mt3 space-y-2" :class="{ 'hidden md:block': !footerContext.isColumnOpen(title) }">
      <slot />
    </ul>
  </div>
</template>
