<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'
import type { Ref } from 'vue'
import { useReviewsState } from '~/composables/reviews'

// const LEGAL_PAGES_PATH_PREFIX = '/legal'

const { t, locale, locales } = useI18n()
const socials = useSocials()

const localeObject = computed(() => (locales as Ref<LocaleObject[]>).value.find(l => l.code === locale.value))
const route = useRoute()

const isProductPage = computed(() => ((route?.name as string) ?? '').includes('products'))

const reviewsState = useReviewsState()

const [
  { data: reviews },
  { data: navigation },
] = await Promise.all([
  useAsyncData('reviews', () => $fetch('/api/reviews', {
    query: { languageCode: locale.value, currencyCode: localeObject.value?.currency },
    onRequest(ctx) {
      ctx.options.headers = { ...ctx.options.headers, 'Content-Language': locale.value, 'Content-Currency': localeObject.value?.currency }
    },
  }).then((result) => {
    reviewsState.value = result.data

    return result.data
  })),
  useAsyncData('navigation', () => fetchContentNavigation({ where: [{ _locale: locale.value }] }), {
    server: false,
    transform: (data) => {
      let nav = data?.map(nav => ({
        ...nav,
        title: t(`page.${nav.title.toLowerCase().replace(/ /g, '_')}.title`),
      }))

      let usefulLinksNavigation = nav.find(nav => nav._path === '/useful-links')

      if (usefulLinksNavigation) {
        usefulLinksNavigation = { ...usefulLinksNavigation }
        const children = usefulLinksNavigation.children ?? []

        if (!children.some(child => child._path === '/account'))
          children.unshift({ _path: '/account', title: t('page.account.title') })

        usefulLinksNavigation.children = children
        nav = nav.map(item => item._path === usefulLinksNavigation?._path ? usefulLinksNavigation : item)
      }

      // const legal = data.find(nav => nav._path.includes(LEGAL_PAGES_PATH_PREFIX))?.children ?? []

      return { footer: nav }
    },
  }),
])
</script>

<template>
  <!-- Pre-footer -->
  <div>
    <PreFooter />
  </div>
  <div class="mx-auto max-w-[1920px] w-full p-inline-5 lg:p-inline-7.5 xl:p-inline-15">
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
      <!-- Commented out content remains unchanged -->
    </div>

    <Footer
      :columns="3"
      :open="[t('general.company.label')]"
      class="mx-auto w-full py4 md:py8"
    >
      <template #columns>
        <FooterColumn :title="t('general.company.label')">
          <ContentQuery v-slot="{ data }" path="footer" find="one" :locale="locale">
            <ContentRenderer :value="data" />
          </ContentQuery>
        </FooterColumn>
        <FooterColumn v-for="nav in navigation?.footer" :key="nav.title" :title="nav.title">
          <li v-for="item in nav.children" :key="item.title">
            <NuxtLinkLocale :to="item._path" class="text-base text-slate-500 hover:text-slate-900">
              {{ item.title }}
            </NuxtLinkLocale>
          </li>
        </FooterColumn>
      </template>
    </Footer>
  </div>
  <div class="mx-auto max-w-[1920px] w-full overflow-hidden border-t n-border-200 py6 p-inline-5 lg:p-inline-7.5 xl:p-inline-15">
    <div class="flex flex-col items-center justify-between md:flex-row">
      <div class="xs:pb1 xs:text-center">
        &copy; {{ new Date().getFullYear() }} U Z O Portal, {{ t('general.all_rights_reserved.label') }}
      </div>
      <div class="flex gap-x-1">
        <div v-for="social in socials" :key="social.label">
          <NLink :href="social.href" n="slate4 hover:slate5" target="_blank">
            <span class="sr-only" v-text="social.label" />
            <NIcon :icon="social.icon" n="xl" />
          </NLink>
        </div>
      </div>
    </div>
    <div v-if="isProductPage" class="pb[100px] sm:hidden" />
  </div>
</template>
