<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div class="mt15">
    <div class="bg-yellow-200">
      <div class="mx-auto max-w-[1920px] w-full p-inline-5 lg:p-inline-7.5 xl:p-inline-15">
        <div class="flex flex-col items-center justify-between py6 md:flex-row md:gap-y-0 md:gap-y-4 xs:gap-y-6">
          <span class="inline-flex flex-1 items-center font-medium uppercase md:justify-left">
            <NIcon icon="i-mingcute:box-2-line" class="text-lg -mt-0.5 n-button-icon" />
            <span class="pl2 text-sm font-bold">{{ t('policies.huge_sortiment') }}</span>
          </span>

          <span class="inline-flex flex-1 items-center font-medium uppercase md:justify-center">
            <NIcon icon="i-mingcute:truck-line" class="text-lg -mt-0.5 n-button-icon" />
            <span class="pl2 text-sm font-bold">{{ t('incentives.free_shipping.title') }}</span>
          </span>

          <span class="inline-flex flex-1 items-center justify-center font-medium uppercase md:justify-right">
            <NIcon icon="i-fluent:arrow-undo-20-filled" class="block text-lg -mt-0.5 n-button-icon" />
            <span class="pl2 text-sm font-bold">{{ t('policies.convient_return_and_exchange_of_good') }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="bg-gray-200">
      <div class="mx-auto max-w-[1920px] w-full p-inline-5 lg:p-inline-7.5 xl:p-inline-15">
        <div class="scrollbar-hidden col-span-1 flex items-center justify-start justify-between gap-2 overflow-x-auto py6">
          <NLogo v-if="$i18n.locale === 'pl'" logo="payments/blik-full" n="lg" class="mr4 mt2 text-visa grayscale-100 dark:text-white" />
          <NLogo v-if="$i18n.locale === 'pl'" logo="payments/przelewy" n="lg" class="mr4 mt2 text-visa grayscale-100 dark:text-white" />
          <NLogo logo="payments/visa" n="lg" class="mr4 mt2 text-visa grayscale-100 dark:text-white" />
          <NLogo logo="payments/mastercard" n="2xl" class="mr4 mt2 grayscale-100" />
          <NLogo logo="payments/apple-pay" n="3xl" class="mr4 mt2 grayscale-100" />
          <NLogo logo="payments/google-pay" n="3xl" class="mr4 mt2 grayscale-100" />
          <NLogo logo="payments/maestro" n="2xl" class="mr4 mt2 grayscale-100" />
          <NLogo logo="payments/amex" n="2xl" class="mr4 mt2 text-amex grayscale-100" />
          <NLogo logo="payments/discover-2" n="3xl" class="mr4 mt2 grayscale-100" />
          <NLogo logo="payments/dinersclub-square" n="3xl" class="mr4 mt2 grayscale-100" />
          <NLogo logo="payments/jcb" n="3xl" class="mr4 mt2 grayscale-100" />
          <NLogo logo="payments/cb" n="3xl" class="mr4 mt2 grayscale-100" />
        </div>
      </div>
    </div>
  </div>
</template>
