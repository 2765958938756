<script setup lang="ts">
import type { UnwrapRef } from 'vue'
import { upperFirst } from 'scule'

const { t, locale, locales, setLocale } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => locales.value.map((l: any) => {
  const formattedNumber = new Intl.NumberFormat(l.language, {
    style: 'currency',
    currency: l.currency,
  }).format(0)
  const localCurrencySymbol = formattedNumber.replace(/[\d.,\s]/g, '')

  return {
    ...l,
    label: `${l.country} (${localCurrencySymbol})`,
    nativeName: upperFirst(l.nativeName),
  }
}))

const selectedLocale = ref(locale.value)
const dropdownPosition = ref({ top: 0, left: 0 })
const localeFlagMap: Record<UnwrapRef<typeof selectedLocale>, string> = {
  en: 'i-circle-flags:us',
  cs: 'i-circle-flags:cz',
  sk: 'i-circle-flags:sk',
  hu: 'i-circle-flags:hu',
  pl: 'i-circle-flags:pl',
  ro: 'i-circle-flags:ro',
}

async function handleClick(locale: string) {
  await setLocale(locale)
  switchLocalePath(locale)
  window.location.reload()
}

// Position dropdown relative to button
function setDropdownPosition() {
  const button = document.getElementById('language-button')
  if (button) {
    const rect = button.getBoundingClientRect()
    dropdownPosition.value = {
      top: rect.bottom + window.scrollY, // Adjust for scroll position
      left: rect.left + window.scrollX,
    }
  }
}

onMounted(() => {
  setDropdownPosition()
  window.addEventListener('resize', setDropdownPosition) // Recalculate on window resize
})
</script>

<template>
  <HMenu as="div" class="relative z-50 inline-block">
    <div>
      <HMenuButton
        id="language-button"
        class="n-button n-button-base whitespace-nowrap active:n-button-active p0.25em focus-visible:n-focus-base n-transition children:m0 hover:n-button-hover n-disabled:n-disabled"
        n="borderless"
        :aria-label="t('general.language_and_currency.label')"
        @click="setDropdownPosition"
      >
        <NIcon :icon="localeFlagMap[selectedLocale]" alt="flag" class="n-icon-button mr2 n-icon" />
        {{ availableLocales.find((l) => l.code === selectedLocale)?.label }}
      </HMenuButton>
    </div>

    <Teleport to="body">
      <Transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 op0"
        enter-to-class="transform scale-100 op100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 op100"
        leave-to-class="transform scale-95 op0"
      >
        <HMenuItems
          v-if="dropdownPosition.top && dropdownPosition.left"
          :style="{ position: 'fixed', top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px`, zIndex: 100 }"
          class="mt-2 w-40 origin-top-right rounded-md bg-white py1 shadow-lg ring-1 ring-black/5 divide-y divide-slate-100 focus:outline-none"
        >
          <div v-for="loc in availableLocales" :key="loc.code" class="bg-white p-1">
            <HMenuItem class="cursor-pointer bg-white" @click="handleClick(loc.code)">
              <div class="group w-full flex items-center rounded-md p-2 text-sm text-slate-600 fw600 hover:bg-slate-50 hover:text-slate-900">
                <NIcon :icon="localeFlagMap[loc.code]" alt="flag" class="n-icon-button mr2 n-icon" />
                {{ loc.label }}
              </div>
            </HMenuItem>
          </div>
        </HMenuItems>
      </Transition>
    </Teleport>
  </HMenu>
</template>
