<script setup lang="ts">
import { ref } from 'vue'

const emit = defineEmits<{
  (e: 'close'): void
}>()
const { t } = useI18n()
const copied = ref(false)
const promoCode = 'BF20'

function copyCode() {
  navigator.clipboard.writeText(promoCode)
  copied.value = true
  setTimeout(() => {
    copied.value = false
  }, 2000)
}
</script>

<template>
  <div class="relative  isolate flex items-center overflow-hidden bg-black  px-6 py-2 sm:px-3.5">
    <img src="/webcontent/sale.svg" alt="" class="absolute h-11 w-12 -left-1 md:-top-1 -top-0.5 md:h-15 md:w-15 -rotate-20">
    <div class="w-full flex flex-col items-center justify-center gap-x-4 md:flex-row">
      <div class="flex flex-col items-center text-center text-white">
        <strong class="text-lg font-semibold fw600 uppercase">{{ t('promobanner.title') }}</strong>
        <span class="text-sm">{{ t('promobanner.subtitle') }}</span>
      </div>

      <div class="flex flex-col gap-0.2 relative">
        <div
          class="flex cursor-pointer items-center gap-2 border text-white rounded-md border-dashed bg-red-700 px-3 py-0.5 align-middle text-red-700 transition-colors hover:bg-orange-900/70"
          @click="copyCode"
        >
          <span class="mt0.5 text-xs text-white fw600">{{ t('promobanner.use_code') }}</span>

          <code class="fw600 text-white">{{ promoCode }}</code>
          <NIcon icon="i-mingcute:copy-line text-white" class="h-4 w-4" />
        </div>
        <span v-if="copied" class="absolute bg-black px1 py0.5 rd-md  top-1 text-xs text-white">{{ t('promobanner.copied') }}</span>
      </div>
    </div>

    <button
      type="button" class="absolute top-4 right-4 text-white transition-colors hover:text-white z200"
      @click="emit('close')"
    >
      <span class="sr-only">{{ t('promobanner.dismiss') }}</span>
      <NIcon icon="i-mingcute:close-line" class="h-5 w-4" />
    </button>
  </div>
</template>
